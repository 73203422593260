import React from "react";
import Layout from "../components/layout";
import ContenidoNosotros from '../components/contenidoNosotros';
import HeaderColor from "../components/headerColor"
import {css } from '@emotion/core';
const IndexPage = props => (
  <Layout>
    <HeaderColor/>
    <ContenidoNosotros/>
  </Layout>
)

export default IndexPage
